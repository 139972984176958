import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Page from "../components/layout/Page"
import SEO from "../components/seo"

import PageHeader from "../components/layout/PageHeader"
import { photoCategories } from "../settings"

const Sitemap = styled.ul`
    font-size: 125%;
    list-style-type: disc;

    ul {
        list-style-type: disc;        
    }
    li {
        padding: 5px;
    }
`

const SitemapPage = () => {
    const data = useStaticQuery(graphql`
        {
            products: allMysqlProducts(sort: {fields: list_order, order: ASC}) {
                edges {
                    product: node {
                        id
                        name
                        url
                        title
                        short_title
                        category_id
                    }
                }
            }
            templates: allMysqlLandingPages {
                edges {
                    page: node {
                        id
                        name
                        title
		                url
                    }
                }
            }
        }
    `);

  return (
    <>
    <SEO page="sitemap" title="Sitemap" />
    <Page
        breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Sitemap", url:null}
        ]}
    >
      <PageHeader title="SignMonkey Sitemap" />

      <Sitemap>
        <li>
            <Link to="/">
                Home &gt;
            </Link>

            <ul>
                <li>
                    <Link to="/products/">Products &gt;</Link>
                    <ul>
                        <li>
                            <Link to="/products/lit-channel-letters-and-logos/">Lit Channel Letters &amp; Logos &gt;</Link>
                            <ul>
                            {data.products.edges.filter(x => x.product.category_id == 1).map(({product}) => (
                                <li key={product.id}>
                                    <Link to={"/products/" + product.url + "/"}>
                                        { product.short_title}
                                    </Link>
                                </li>
                            ))}
                            </ul>
                        </li>
                        <li>
                            <Link to="/products/lit-shapes/">Lit Shapes &gt;</Link>
                            <ul>
                            {data.products.edges.filter(x => x.product.category_id == 2).map(({product}) => (
                                <li key={product.id}>
                                    <Link to={"/products/" + product.url + "/"}>
                                        { product.short_title}
                                    </Link>
                                </li>
                            ))}
                            </ul>
                        </li>
                        <li>
                            <Link to="/products/lit-cabinets/">Lit Cabinets &gt;</Link>
                            <ul>
                            {data.products.edges.filter(x => x.product.category_id == 3).map(({product}) => (
                                <li key={product.id}>
                                    <Link to={"/products/" + product.url + "/"}>
                                        { product.short_title}
                                    </Link>
                                </li>
                            ))}
                            </ul>
                        </li>
                        <li>
                            <Link to="/products/dimensional-letters-and-logos/">Dimensional Letters & Logo Shapes &gt;</Link>
                            <ul>
                            {data.products.edges.filter(x => x.product.category_id == 100175).map(({product}) => (
                                <li key={product.id}>
                                    <Link to={"/products/" + product.url + "/"}>
                                        { product.short_title}
                                    </Link>
                                </li>
                            ))}
                            </ul>
                        </li>                        
                        <li><Link to="/products/accessories/">Accessories</Link></li>
                    </ul>
                </li>
                    
                <li><Link to="/builder/">Design &amp; Price a Sign</Link></li>
                <li><Link to="/sign-price-request/">Submit Your Design for Price </Link></li>               
                <li>
                    <Link to="/templates/">Sign Templates &gt;</Link>
                    <ul>
                    <ul>
                            {data.templates.edges.map(({page}) => (
                                <li key={page.id}>
                                    <Link to={"/pages/" + page.name + "/"}>
                                        { page.title}
                                    </Link>
                                </li>
                            ))}
                            </ul>
                    </ul>
                </li>
                <li>
                    <Link to="/why/">Why SignMonkey &gt;</Link>
                    <ul>
                        <li><Link to="/why/easy-installation/">Easy Installation</Link></li>
                        <li><Link to="/why/fast-production/">Fast Production</Link></li>
                        <li><Link to="/why/pricing/">Pricing</Link></li>
                        <li><Link to="/why/videos/">Videos</Link></li>
                        <li>
                            <Link to="/why/photos/">Photos</Link>
                            <ul>
                                {photoCategories.map(cat => (
                                <li key={cat}>
                                    <Link to={"/why/photos/?category=" + encodeURIComponent(cat)}>{cat}</Link>
                                </li>
                                ))}
                            </ul>
                        </li>
                        <li><Link to="/why/reviews/">Reviews</Link></li>
                        <li><Link to="/blog/">SignMonkey Blog</Link></li>
                        <li><Link to="/signencyclopedia/">Sign Encyclopedia</Link></li>
                    </ul>
                </li>

                <li>
                    <Link to="/support/">Support &gt;</Link>
                    <ul>
                        <li><Link to="/support/frequently-asked-questions/">Frequently Asked Questions</Link></li>
                        <li><Link to="/support/installation/">Installation Instructions</Link></li>
                        <li><Link to="/support/permits/">Permit Information</Link></li>
                        <li><Link to="/support/resources/">Sign Resources</Link></li>
                    </ul>
                </li>
                <li><Link to="/account/">Your Account</Link></li>
                <li><Link to="/contact-us/">Contact Us</Link></li>
                
                <li><Link to="/shipping-policy/">Shipping Policy</Link></li>
                <li><Link to="/warranty/">Warranty</Link></li>

                <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                <li><Link to="/terms/">Terms of Service</Link></li>
                <li><Link to="/intellectual-property/">Intellectual Property</Link></li>
            </ul>
        </li>
      </Sitemap>
    </Page>
  </>
    )
}

export default SitemapPage